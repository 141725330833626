<!-- 售后产品列表 -->
<template>
  <div class="afterproductlist">
    <div v-if='productList&&productList.jobList&&productList.jobList.length>0' class="item" v-for='item,index in productList.jobList'>
      <div class="img">
        <img :src="item.ImageUrl | imgsrc(that)">
      </div>
      <div class="info">
        <h6>{{item.JobSubsTypesName}}</h6>
        <p>数量：{{item.DzproductnumberName}}</p>
        <p>规格：{{item.DzmaterialName}}</p>
        <p>纸巾尺寸：{{item.DzspecificationName}}</p>
        <p>x{{item.JobNum}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'afterproductlist',
  props: {
    productList: Object
  },
  data(){
    return {
      that:this
    }
  },
  methods:{
  },
  mounted(){
  },
  filters:{
    imgsrc(v,that){
      return that._src + v
    }
  }
}
</script>
<style  lang="scss" scoped>
.afterproductlist {
  border-bottom:1px solid #ebebeb;padding-bottom:30px;
  .item {
    display: flex;justify-content: flex-start;align-items: flex-start;margin-bottom:10px;
    .img {
      width: 125px;height: 125px;border:1px solid #f2f2f2;display: flex;justify-content: center;align-items: center;
      img {
        display: block;width: 100px;height: 100px;
      }
    }
    .info {
      margin-left:20px;
      h6 {
        font-size: 20px;font-weight: normal;margin-bottom:15px;
      }
      p {
        font-size: 14px;color:#797979;margin-bottom:2px;
      }
    }
  }
}
</style>
