<!-- 申请售后 -->
<template>
  <div class="applyafter">
    <!-- 面包屑 -->
    <crumbs class='crumbs'  :location='location' @exportCls='exportCls'></crumbs>
    <div class="center">
      <afterproductlist :productList='productList'></afterproductlist>
      <aftertypes></aftertypes>
    </div>
    <usualquestion class='usualquestion'></usualquestion>
  </div>
</template>
<script>
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑导航
import afterproductlist from '@/components/afterproductlist/afterproductlist.vue';//售后产品列表
import aftertypes from '@/components/aftertypes/aftertypes.vue';//售后类型选择
import usualquestion from '@/components/usualquestion/usualquestion.vue';//常见问题
export default {
  name: 'applyafter',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return  {
      htmltitle:'申请售后-微微定',
      location:[{Name:'首页'},{Name:'我的订单'},{Name:'申请售后'}],//面包屑
      productList:{},//退货产品列表
    }
  },
  components: {
    crumbs,
    afterproductlist,
    aftertypes,
    usualquestion
  },
  props: {
    msg: String
  },
  methods:{
    exportCls(v){
      // 面包屑点击
      // console.log(v)
      if(v.Name=='首页'){
        this._router({then:()=>{
          this.$router.push('/index.html')
        }})
      }
      if(v.Name=='我的订单'){
        this._router({then:()=>{
          this.$router.push({path: "/usercenterorder.html"})
          // this._open({path: "/usercenterorder"})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPaht+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    initGet(){
      this._axios({then:()=>{
        // 申请售后页请求
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/RefundApplyInitPage?OrderName='+this._spf(this.$route.query.OrderName)+'&ProductId='+this._spf(this.$route.query.ProductId),this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
            this.productList = res.data.Data
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.initGet()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.applyafter {
  background: #fff;padding-bottom:100px;
  .crumbs {
    padding:10px 0;
  }
  .center {
    border:1px solid #ececec;width: 1138px;margin:0 auto;padding:30px;
  }
  .usualquestion {
    width: 1200px;margin:0 auto;margin-top:20px;
  }
}
</style>
