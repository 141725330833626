<!-- 常见问题 -->
<template>
  <div class="usualquestion">
    <h6 class="h6">常见问题</h6>
    <div class="item">
      <h5>1.迟迟不发货怎么办？</h5>
      <p>亲，如果迟迟未发货，可能是您的订单需要较长时间来制作，可联系客服具体了解</p>
    </div>
    <div class="item">
      <h5>1.如何申请退款？</h5>
      <p>亲，在您的订单完成之后可先联系客服再申请退款</p>
    </div>
    <div class="item">
      <h5>1.申请退款后，交易退款成功，钱退到哪里？</h5>
      <p>亲，当订单状态显示【退款成功】时，钱款会退回原先的支付渠道</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'usualquestion',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.usualquestion {
  background: #f8f8f8;height: 310px;
  h6 {
    font-size: 20px;padding-top:50px;margin-left:20px;
  }
  .item {
    margin-left:20px;
    h5 {
      margin-top:20px;font-weight: normal;font-size: 16px;
    }
    p {
      color:#a2a09f;
    }
  }
}
</style>
