<!-- 售后类型选择-->
<template>
  <div class="aftertypes">
    <h6 class="h6">选择服务类型</h6>
    <div class="list">
      <div class="item" @click='applypage(1)'>
        <svg><use xlink:href="#icontuikuan1"></use></svg>
        <div class="info">
          <h6>我要退款（无需退货）</h6>
          <p>没收到货，或与卖家协商同意不用退货只退款</p>
        </div>
      </div>
      <div class="item" @click='applypage(2)'>
        <svg><use xlink:href="#icontuihuotuikuan1"></use></svg>
        <div class="info">
          <h6>我要退货退款</h6>
          <p>已收到货，需要退还收到的货物</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aftertypes',
  props: {
    msg: String
  },
  methods:{
    applypage(v){
      // 跳转申请页
      // console.log(v)
      if(v===1){
        this._router({then:()=>{
          this.$router.push({path:"/applyaftercause?ProductId="+this._spf(this.$route.query.ProductId)+'&OrderName='+this._spf(this.$route.query.OrderName)+'.html'})
        },exp:true,catch:()=>{
          // this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
          var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        }})
      }
      if(v===2){
        this._router({then:()=>{
          this.$router.push({path:"/returngood?ProductId="+this._spf(this.$route.query.ProductId)+'&OrderName='+this._spf(this.$route.query.OrderName)+'.html'})
        },exp:true,catch:()=>{
          // this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
          var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        }})
      }
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aftertypes {
  .h6 {
    font-weight: normal;font-size: 20px;height: 60px;line-height: 60px;
  }
  .list {
    zoom:1;
    .item {
      float:left;border:1px solid #ebebeb;border-radius: 3px;width: 543px;height: 143px;margin-right: 48px;display: flex;justify-content:center;align-items: center;cursor: pointer;
      svg {
        width: 65px;height: 65px;fill: #f96464;
      }
      .info {
        margin-left:10px;
        h6 {
          font-size: 22px;margin-bottom:5px;
        }
        p {
          font-size:18px;color:#ababb3;
        }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    &:after {
      content:'';display: block;clear:both;
    }
  }
}
</style>
